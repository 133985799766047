import { IdVerification, KYCUseCaseResolver } from '@features/kyc/domain';
import { GetFeatureFlagUsecase } from 'app/core/usecases/get-feature-flag.usecase';
import { CheckUserFeatureExistsUseCase } from 'app/core/usecases/user/check-user-feature-exists.usecase';
import { KYC_USER_FEATURE } from 'app/presentation/shared/constants';
import { KYC_FEATURE } from 'app/presentation/shared/constants/feature-flags';

export function getMerchantIdVerification(
  getFeatureFlagUsecase: GetFeatureFlagUsecase,
  checkUserFeatureExistsUseCase: CheckUserFeatureExistsUseCase,
): Promise<IdVerification> {
  return new Promise<IdVerification>((resolve, reject) => {
    getFeatureFlagUsecase.execute(KYC_FEATURE).subscribe({
      next: (flagIsOn: boolean) => {
        const isUserFeatureExist = checkUserFeatureExistsUseCase.execute(KYC_USER_FEATURE);
        if (flagIsOn && isUserFeatureExist) {
          KYCUseCaseResolver.getVerificationIdStatusUseCase().then((response) => {
            resolve(response);
          });
        } else {
          // Resolve with default verification status if conditions are not met
          resolve({ isVerified: true, isDuplicate: false });
        }
      },
    });
  });
}
