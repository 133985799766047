import { NgClass, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-on-hover-tooltip',
  standalone: true,
  imports: [NgClass, NgStyle, NgTemplateOutlet, NgIf],
  templateUrl: './on-hover-tooltip.component.html',
  styleUrls: ['./on-hover-tooltip.component.scss'],
})
export class OnHoverTooltipComponent {
  @Input() heading?: string;

  @Input() content: string;

  @Input() backgroundColor: string;

  @Input() fullWidth = false;

  @Input() arrowDirection: 'top' | 'bottom' | 'left' | 'right';

  @Input() targetTemplate: any;
}
